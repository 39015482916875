import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addToCart } from './actions/cartActions'
import PicaPopup from './PicaPopup';
import Popup from "reactjs-popup";
import CloseImg from '../img/close.png';
import BackgroundImg from '../img/fonas.jpg';

class Product extends Component {
  handleClick = (id, kaina, priedai, atmesti, padazas, dydis, title) => {
    this.props.addToCart(id, kaina, priedai, atmesti, padazas, dydis, title);
  }
  render() {
    if (this.props.tipas === "SnacksDouble" || this.props.tipas === "PitaG" || this.props.tipas === "Pita" || this.props.tipas === "Kebab") {
      return <div className={this.props.klase}>
        <div className="ImgBox"><img className={this.props.imgklase} src={this.props.image} alt="" /></div>
        <div className="Double">
          <div>
            <div className="Pavadinimas">{this.props.title}</div>
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id, this.props.kaina, null, null, null, this.props.size1, this.props.title) }}>
              <div className="Mygelis">{this.props.size1} {this.props.kaina}€</div>
            </button>
            {/* <div className="Pavadinimas">{this.props.title}</div>
            <div className="Kaina">{this.props.kaina} Eur</div>
          </div>
          <div> */}
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id2, this.props.kaina2, null, null, null, this.props.size2, this.props.title) }}>
              <div className="Mygelis">{this.props.size2} {this.props.kaina2}€</div>
            </button>
            {/* <div className="Kaina">{this.props.kaina2} Eur</div> */}
          </div>
        </div>
      </div>;
    } else if (this.props.tipas === "SnacksTripple" || this.props.tipas === "KebabTripple") {
      return <div className={this.props.klase}>
        <div className="ImgBox"><img className={this.props.imgklase} src={this.props.image} alt="" /></div>
        <div className="Double">
          <div>
            <div className="Pavadinimas">{this.props.title}</div>
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id, this.props.kaina, null, null, null, this.props.size1, this.props.title) }}>
              <div className="Mygelis">{this.props.size1} {this.props.kaina}€</div>
            </button>
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id2, this.props.kaina2, null, null, null, this.props.size2, this.props.title) }}>
              <div className="Mygelis">{this.props.size2} {this.props.kaina2}€</div>
            </button>
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id3, this.props.kaina3, null, null, null, this.props.size3, this.props.title) }}>
              <div className="Mygelis">{this.props.size3} {this.props.kaina3}€</div>
            </button> 
          </div>
        </div>
      </div>;
    } else if (this.props.tipas === "Pizza") {
      return <div className={this.props.klase}>
        <div className="PicosImgBlock ImgBox">
          <Popup trigger={<button className="PicosBtn"> <img className={this.props.imgklase} src={this.props.image} alt="" /></button>} closeOnDocumentClick>

            {close => (
              <div className="modal">
                <div className="closeDiv">
                  <button><img className="closeBtn" src={CloseImg} alt="close" onClick={close} /></button>
                </div>
                <PicaPopup id={this.props.id} imgklase="PicosImg" image={this.props.image} number={this.props.number} desc={this.props.desc} title={this.props.title} kaina={this.props.kaina} handleClose={close} />
              </div>
            )}
          </Popup>
          <img className="PicosNr" src={this.props.number} alt="" />
        </div>
        <div className="Sudetis"><b>{this.props.title}</b>{this.props.desc.map(txt => <span key={Math.random().toString(36).substr(2, 9)}>, {txt}</span>)}</div>

      </div>;
    } else {
      return <div className={this.props.klase}>
        <div className="ImgBox"><img className={this.props.imgklase} src={this.props.image} alt="" /></div>
        <div className="Double">
          <div>
            <div className="Pavadinimas">{this.props.title}</div>
            <button className="ProduktoInfo" onClick={() => { this.handleClick(this.props.id, this.props.kaina, null, null, null, null, this.props.title) }}>
              <div className="Mygelis">Į krepšelį {this.props.kaina}€</div>
            </button>
            {/* <div className="Kaina">{this.props.kaina} Eur</div> */}
            {this.props.papildomai ? (
              <div>+{this.props.papildomai.toLocaleString(undefined, { minimumFractionDigits: 2 })}€ tara</div>
            ) : (<div></div>)}

          </div>
        </div>
      </div>;
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id, kaina, priedai, atmesti, padazas, dydis, title) => { dispatch(addToCart(id, kaina, priedai, atmesti, padazas, dydis, title)) }
  }
}

export default connect(null, mapDispatchToProps)(Product)