import MainPage from './components/MainPage';
import './App.css';
import './mobile.css';
import {BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Picos from './components/Picos';
import Uzkandziai from './components/Uzkandziai';
import Gerimai from './components/Gerimai';
import Contacts from './components/Contacts';
import Header from './components/Header';
function App() {
  return (
    <Router>
          <div className="App">
          <Switch>
          <Route path="/picos">
            <Header/>
            <Picos />
          </Route>
          <Route path="/uzkandziai">
            <Header/>
            <Uzkandziai />
          </Route>
          <Route path="/gerimai">
            <Header/>
            <Gerimai />
          </Route>
          <Route path="/contacts">
            <Header/>
            <Contacts />
          </Route>
          <Route path="/">
            <MainPage />
          </Route>

          </Switch>
        </div>
    </Router>

  );
}

export default App;
