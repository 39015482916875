import Logo from '../img/logo-intro.png';
import MainPic from '../img/intro.jpg';
import MainPicMob from '../img/introMob.jpg';
import { Link } from "react-router-dom";

function MainPage() {
  return (
    <div className="MainPage">
      <div>
      <Link to="/picos"><img id="webPic" className="MainPage" src={MainPic} alt="" />
        <img id="mobPic" className="MainPage" src={MainPicMob} alt="" />
      </Link>
       {/*<p class="MainLogo">*/}
        {/* <img src={Logo} width='200px' height='200px'  alt="" /> */}
        {/* <p class="MainMenu">
            <Link to="/picos">Pica</Link>
            <p class="MainMenuP"/>
            <Link to="/uzkandziai">Užkandžiai</Link>
            <p class="MainMenuP"/>
            <Link to="/gerimai">Gėrimai</Link> 
            <p class="MainMenuP"/>
            <Link to="/contacts">Pristatymas</Link>
        </p>  */}
       {/* </p>      */}
      </div>
    </div>
  );
}

export default MainPage;
