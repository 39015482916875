import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeItem, addQuantity, subtractQuantity } from './actions/cartActions';

class Cart extends Component {
    //to remove the item completely
    handleRemove = (id) => {
        this.props.removeItem(id);
    }
    //to add the quantity
    handleAddQuantity = (id) => {
        this.props.addQuantity(id);
    }
    //to substruct from the quantity
    handleSubtractQuantity = (id) => {
        this.props.subtractQuantity(id);
    }

    render() {

        let addedItems = this.props.addedItems.length ?
            (
                this.props.addedItems.map(item => {
                    const pizza = item.type === 'Pizza';
                    return (
                        <div className="CartItem">
                            <li className="CartItemInfo" key={Math.random().toString(36).substr(2, 9)}>
                                <div>
                                    <div>{pizza ? (<span className="CartTitle">{item.description}</span>) :
                                        (<span className="CartTitle">{item.title}</span>)}
                                        {item.dydis ? (<span className="CartTitle">, {item.dydis}</span>) :
                                            (<div></div>)}
                                        {pizza ? (
                                            <span className="CartText"> cm</span>
                                        ) : (<div></div>)}
                                        {item.atmesti ? (
                                            <span>{item.atmesti.map((atmest) => <span>, -{atmest}</span>)}</span>
                                        ) : (<div></div>)}
                                        {item.priedai ? (
                                            <span>{item.priedai.map((priedas) => <span>, +{priedas.name}</span>)}</span>
                                        ) : (<div></div>)}

                                    </div>

                                    {item.padazas ? (
                                        <div>
                                            <span className="CartTitle">Padažas</span>
                                            <span className="CartText"> {item.padazas}</span>
                                        </div>
                                    ) : (<div></div>)}


                                    {/* {item.tara ? (
                                    <p><b>Tara:</b> {item.tara} Eur</p>
                                ) : (<p></p>)} */}
                                    {/* <p>
                                    <b>Kiekis: {item.quantity}</b>
                                </p> */}
                                    {/* <div className="add-remove">
                                            <button className="waves-effect waves-light btn pink remove" onClick={()=>{this.handleAddQuantity(item.id)}}>add</button>
                                            <button className="waves-effect waves-light btn pink remove" onClick={()=>{this.handleSubtractQuantity(item.id)}}>drop</button>
                                        </div> */}
                                    {/* <button className="waves-effect waves-light btn pink remove" onClick={()=>{this.handleRemove(item.id)}}>Remove</button> */}
                                </div>

                            </li>
                            <li className="CartItemPrice"> <span className="CartTitle">{item.quantity} x {item.price}€</span></li></div>
                    )
                })
            ) :

            (
                <p>Krepšelis tuščias.</p>
            )
        return (
            <div className="container">
                <div className="cart">
                    <ul className="CartItems">
                        {addedItems}
                    </ul>
                    <hr className="" />
                    {/* {this.props.shipping > 0 ? (
                        <ul className="CartItems">
                            <div className="CartItem">
                                <li className="CartTitle CartItemInfo">Pristatymas</li>
                                <li className="CartItemPrice"> <span className="CartTitle">{this.props.shipping}€</span></li>
                            </div>
                        </ul>
                    ) : (<div></div>)} */}
                    <ul className="CartItems">
                        <div className="CartItem">
                            <li className="CartItemInfo" />
                            <li className="CartItemPrice"> <span className="CartTitle">{Math.round((this.props.total) * 100) / 100}€</span></li>
                        </div>
                    </ul>
                    <button className="CartOrder">
                        <div className="Mygelis">Skambinkite <a href="tel:852603100">85 260 31 00</a> ir užsakykite</div>
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addedItems: state.addedItems,
        total: state.total,
        shipping: state.shipping
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (id) => { dispatch(removeItem(id)) },
        addQuantity: (id) => { dispatch(addQuantity(id)) },
        subtractQuantity: (id) => { dispatch(subtractQuantity(id)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart)