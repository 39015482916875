import Product from './Product';
import React, { Component } from 'react';
import { connect } from 'react-redux'

class Gerimai extends Component{

  render(){
    let itemList = this.props.items.map(item=>{
      return(
        (() => {
          switch (item.type) {
            case "Drinks":   return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasGerimai" imgklase="ProduktasGerimaiImg" image={item.img} title={item.title} kaina={item.price} papildomai={item.tara} />      ;
          }
        })() 
      )
  })
  return(

           <div className="ProduktaiGerimai Produktai">
              {itemList}
          </div>

  )
  }
}

const mapStateToProps = (state)=>{
  return {
    items: state.items
  }
}

export default connect(mapStateToProps)(Gerimai)
