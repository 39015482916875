import Logo from '../img/logo.png';
import BannerImg from '../img/Banner.png';
import CartImg from '../img/Cart.png';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from "reactjs-popup";
import CloseImg from '../img/close.png';
import Cart from './Cart';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listItems: [
                { id: 1, name: 'Picos', linkas: '/picos', active: false },
                { id: 2, name: 'Užkandžiai', linkas: '/uzkandziai', active: false },
                { id: 3, name: 'Gėrimai', linkas: '/gerimai', active: false },
                { id: 4, name: 'Pristatymas', linkas: '/contacts', active: false }
            ],
            bannerId: 1,
            bannerText: "3-čia pica nemokamai"
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.changeBanner(), 4000);
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }

    showContentHandler = (id) => {
        const listItems = [...this.state.listItems];
        for (let item of listItems) {
            item.active = false;
        }

        const listIndex = this.state.listItems.findIndex(item => {
            return item.id === id;
        });
        const listItem = {
            ...this.state.listItems[listIndex]
        }
        listItem.active = true;
        listItems[listIndex] = listItem;
        this.setState({ listItems: listItems });
    }
    changeBanner = () => {
        let newId;
        if (this.state.bannerId < 4) { 
          newId = this.state.bannerId + 1;
        } else {
            newId = 1;
        }
        switch (newId) {
            case 1: this.setState({ bannerText: "3-čia pica nemokamai"}); break;
            case 2: this.setState({ bannerText: "3-čias kebabas nemokamai"}); break;
            case 3: this.setState({ bannerText: "3-čia pita-gyros nemokamai"}); break;
            // case 4: this.setState({ bannerText: "Kas 6-tas kavos puodelis nemokamai"}); break;
        }
          this.setState({ bannerId: newId}); 
      }
    render() {
        return (<div className='Header'>
            <div className='HeaderContents'>
                <div className='HeaderLine'><img className='HeaderLogo' src={Logo} width='70px' height='70px' alt="" />
                    <button className="Banner" onClick={(e) => this.changeBanner()}>
                        <div className="Mygelis">{this.state.bannerText}</div>
                    </button>
                    <Popup trigger={<button className="HCart" onClick=''>
                        <img className='HCartImg' src={CartImg} height='45px' alt="" />
                        <div className={this.props.countItems > 9 ? 'HCartItems10' : 'HCartItems'}>{this.props.countItems}</div>
                    </button>} closeOnDocumentClick>

                        {close => (
                            <div className="modal">
                                <div className="closeDiv">
                                    <button><img className="closeBtn" src={CloseImg} alt="close" onClick={close} /></button>
                                </div>
                                <Cart handleClose={close} />
                            </div>
                        )}
                    </Popup>
                </div>
                <div className="MenuBar">
                    <ul>
                        {this.state.listItems.map(item => {
                            return (
                                <li className='MenuLink' key={item.id} ><Link onClick={() => this.showContentHandler(item.id)} href="#" className={item.active ? 'MenuLinkBold  ' : ''} to={item.linkas}>{item.name}</Link><hr href="#" className={item.active ? 'MenuLine' : 'MenuLineTr'} /></li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countItems: state.countItems
    }
}

export default connect(mapStateToProps)(Header);