import React, { Component } from 'react';

class Priedas extends Component {
  constructor() {
    super();
    this.state = {
      isChecked: false
    }
  }
  handleCheck = () => {
    this.setState({isChecked: !this.state.isChecked})
    if (this.state.isChecked){
      this.props.buttonClick(this.props, false)     
    } else {
      this.props.buttonClick(this.props, true)
    }
    
    }
  render() {
      return( <div className="Priedas">
        <img className="PriedasImg" src={this.props.image}  alt="" />
        <div className="PriedasPavadinimas">{this.props.name}</div>
        <div className="PriedasKaina">
        <div className="PriedasPlus">
            <label>
                <input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheck} /><span>+</span>
            </label>
          </div>  
          {(() => {
        switch (this.props.size) {
          case 30:   return <div>{this.props.kaina30} €</div>;
          case 40: return <div>{this.props.kaina40} €</div>;
          case 50:  return <div>{this.props.kaina50} €</div>;
          default:      return <div></div>;
        }
      })()}
          
          </div>
      </div>
      )
}
}

export default Priedas