import Product from './Product';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import nemokamaiPita from '../img/3gyrosNemokamai.png';
import nemokamaiKebabas from '../img/3kebabasNemokamai.png'; 

class Uzkandziai extends Component{
  render(){
    let itemListSnacks = this.props.items.map(item=>{
      return(
        (() => {
          switch (item.type) {
            case "Snacks":   return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg" image={item.img} title={item.title} kaina={item.price}/>      ;
            case "SnacksDouble":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2}/>      ;
            case "SnacksTripple":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} id3={item.id3} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2} size3={item.size3} kaina3={item.price3}/>      ;
          }
        })() 
      )
  })
  let itemListPitaG = this.props.items.map(item=>{
    return(
      (() => {
        switch (item.type) {
          case "PitaG":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg GyrosImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2}/>      ;
        }
      })() 
    )
})
let itemListPita = this.props.items.map(item=>{
  return(
    (() => {
      switch (item.type) {
        case "Pita":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2}/>      ;
      }
    })() 
  )
})
let itemListKebab = this.props.items.map(item=>{
  return(
    (() => {
      switch (item.type) {
        case "Kebab":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg KebabImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2}/>      ;
        case "KebabTripple":   return <Product tipas={item.type} key={item.id} id={item.id} id2={item.id2} id3={item.id3} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg KebabImg" image={item.img} title={item.title} kaina={item.price} size1={item.size1} size2={item.size2} kaina2={item.price2} size3={item.size3} kaina3={item.price3}/>      ;
      }
    })() 
  )
})
let itemListSouce = this.props.items.map(item=>{
  return(
    (() => {
      switch (item.type) {
        case "Souce":   return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg SouceImg" image={item.img} title={item.title} kaina={item.price}/>      ;
      }
    })() 
  )
})
let itemListIceCream = this.props.items.map(item=>{
  return(
    (() => {
      switch (item.type) {
        case "IceCream":   return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasUzkandziai" imgklase="ProduktasUzkandziaiImg IceCreamImg" image={item.img} title={item.title} kaina={item.price}/>      ;
      }
    })() 
  )
})
  return(
    <div className="Produktai">
      <div className="PicaTitle">
          <img className="PicaSu" src={nemokamaiPita} alt="" />
        </div>
          <div className="ProduktaiUzkandziai">
              {itemListPitaG}
          </div>
           <div className="ProduktaiUzkandziai">
              {itemListPita}
          </div>
          <div className="PicaTitle">
            <img className="PicaSu" src={nemokamaiKebabas} alt="" />
          </div>
          <div className="ProduktaiUzkandziai">
          {itemListKebab}
          </div>
          <div className="ProduktaiUzkandziai">
          {itemListSnacks}
          </div>
          <div className="ProduktaiUzkandziai">
          {itemListSouce}
          </div>
          <div className="ProduktaiUzkandziai">
          {itemListIceCream}
          </div>
          </div>
  )
  }
}

const mapStateToProps = (state)=>{
  return {
    items: state.items
  }
}

export default connect(mapStateToProps) (Uzkandziai)
