import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { addToCart } from './actions/cartActions'
import Carousel from 'react-elastic-carousel';
import Suris from '../img/Pizza/Priedas_suris.png';
import Kumpis from '../img/Pizza/Priedas_kumpis.png';
import Saliamis from '../img/Pizza/Priedas_saliamis.png';
import Farsas from '../img/Pizza/Priedas_farsas.png';
import Jautiena from '../img/Pizza/Priedas_jautiena.png';
import Krevetes from '../img/Pizza/Priedas_krevetes.png';
import Vistiena from '../img/Pizza/Priedas_vistiena.png';
import Sonine from '../img/Pizza/Priedas_sonine.png';
import Svogunai from '../img/Pizza/Priedas_svogunai.png';
import Paprika from '../img/Pizza/Priedas_paprika.png';
import Agurkeliai from '../img/Pizza/Priedas_agurkeliai.png';
import DzPomidorai from '../img/Pizza/Priedas_dzpomidorai.png';
import VPomidorai from '../img/Pizza/Priedas_vpomidorai.png';
import Jelapenai from '../img/Pizza/Priedas_jelapenai.png';
import Svoguneliai from '../img/Pizza/Priedas_svoguneliai.png';
import Baklazanai from '../img/Pizza/Priedas_baklazanai.png';
import Porai from '../img/Pizza/Priedas_porai.png';
import Brokoliai from '../img/Pizza/Priedas_brokoliai.png';
import Pievagrybiai from '../img/Pizza/Priedas_pievagrybiai.png';
import Alyvuoges from '../img/Pizza/Priedas_alyvuoges.png';
import Ananasai from '../img/Pizza/Priedas_ananasai.png';
import Rukola from '../img/Pizza/Priedas_rukola.png';
import Spinatai from '../img/Pizza/Priedas_spinatai.png';
import Bazilikas from '../img/Pizza/Priedas_bazilikas.png';
import RSuris from '../img/Pizza/Priedas_rsuris.png';
import Priedas from './Priedas.js';
import SudetisImg from '../img/close.png';

class PicaPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kaina: this.props.kaina,
      checkedSize: 30,
      priedai: [],
      atmesti: [],
      souce: ''
    }
    this.addPrice = this.addPrice.bind(this);
  }
  handleClick = (id) => {
    this.props.addToCart(id, this.state.kaina, this.state.priedai, this.state.atmesti, this.state.souce, this.state.checkedSize, null);
    this.props.handleClose();
  }

  addPrice = (priedas, add) => {
    let price;
    switch (this.state.checkedSize) {
      case 30:
        price = priedas.kaina30;
        break;
      case 40:
        price = priedas.kaina40;
        break;
      case 50:
        price = priedas.kaina50;
        break;
      default:
      // code block
    }
    if (add) {
      this.setState({ kaina: Math.round((this.state.kaina + price) * 100) / 100 })
      this.setState({ priedai: [...this.state.priedai, priedas] })
    } else {
      let naujiPriedai = this.state.priedai.filter(item => priedas.name !== item.name)
      this.setState({ kaina: Math.round((this.state.kaina - price) * 100) / 100 })
      this.setState({ priedai: naujiPriedai })
    }
  }
  onSizeChanged = (size) => {
    let oldPrice;
    let newPrice;
    if (size !== this.state.checkedSize) {
      switch (this.state.checkedSize) {
        case 30:
          oldPrice = 11.49;
          break;
        case 40:
          oldPrice = 17.29;
          break;
        case 50:
          oldPrice = 19.99;
          break;
        default:
        // code block
      }
      switch (size) {
        case 30:
          newPrice = 11.49;
          break;
        case 40:
          newPrice = 17.29;
          break;
        case 50:
          newPrice = 19.99;
          break;
        default:
        // code block
      }
      // perskaiciuoti priedu kainas
      let oldPricePriedai = 0;
      let newPricePriedai = 0;
      this.state.priedai.map(item => {
        return (
          (() => {
            switch (this.state.checkedSize) {
              case 30: oldPricePriedai = oldPricePriedai + item.kaina30; break;
              case 40: oldPricePriedai = oldPricePriedai + item.kaina40; break;
              case 50: oldPricePriedai = oldPricePriedai + item.kaina50; break;
            }
            switch (size) {
              case 30: newPricePriedai = newPricePriedai + item.kaina30; break;
              case 40: newPricePriedai = newPricePriedai + item.kaina40; break;
              case 50: newPricePriedai = newPricePriedai + item.kaina50; break;
            }
          })()
        )
      })


      /** change price */
      this.setState({ kaina: Math.round((this.state.kaina - oldPrice + newPrice - oldPricePriedai + newPricePriedai) * 100) / 100 })
      this.setState({ checkedSize: size })
    }
  }
  onSouceChanged = (souce) => {
    if (souce !== this.state.souce) {
      this.setState({ souce: souce })
    }
  }
  removeItem = (priedas) => {
    let element = document.getElementById(priedas)
    

    if (this.state.atmesti.indexOf(priedas) > -1) {
      let naujasArr = this.state.atmesti.filter(item => priedas !== item)
      this.setState({ atmesti: naujasArr })
      ReactDOM.findDOMNode(element).style.backgroundColor = 'transparent'
    } else {
      this.setState({ atmesti: [...this.state.atmesti, priedas] })
      ReactDOM.findDOMNode(element).style.backgroundColor = '#FDC300'
    }
  }
  render() {

    return (
      <div className="PicaPopup">
        <div className="content">
          <div className="Kaire">
            <div className="PicosImgBlock">
              <img className="PicosImgStatic" src={this.props.image} alt="" />
              <img className="PicosNrStatic" src={this.props.number} alt="" />
            </div>
            <button className="PicaAddToCart" onClick={() => { this.handleClick(this.props.id) }}>
              <div className="Mygelis">Į krepšelį {this.state.kaina}€</div>
            </button>
          </div>
          <div className="Desine">
            <div className="">
              <div style={{ display: 'flex' }}>
                <div className="SudetisMinus">
                <button className="SudetisBtn SudetisTitle" type="checkbox" onClick={(e) => this.removeItem(this.props.title)}>{this.props.title}<img className="SudetisImg" id={this.props.title} src={SudetisImg}/>
                </button>
                </div>
              </div>
            </div>
            <ul className="picaSudetis">
              {this.props.desc.map(txt => <li className="SudetisMinus" key={txt}>
                <button className="SudetisBtn" type="checkbox" onClick={(e) => this.removeItem(txt)}>{txt}<img className="SudetisImg" id={txt} src={SudetisImg}/>
                </button>
              </li>)}
            </ul>

            <table className="PicaSizeBox">
              <tbody>
                <tr className="PicaSize">
                  <td ><input id="size1" type="radio" checked={this.state.checkedSize === 30} onChange={(e) => this.onSizeChanged(30)} className="PicaSize" /><label htmlFor="size1">30 cm</label></td>
                   <td><input id="size2" type="radio" checked={this.state.checkedSize === 40} onChange={(e) => this.onSizeChanged(40)} className="PicaSize" /><label htmlFor="size2">40 cm</label></td>
                  {/*<td><input id="size3" type="radio" checked={this.state.checkedSize === 50} onChange={(e) => this.onSizeChanged(50)} className="PicaSize" /><label htmlFor="size3">50 cm</label></td> */}
                </tr>
              </tbody>
            </table>
            <div className="PriedaiBox">
              <span className="Priedai">Priedai</span>
              <Carousel itemsToShow={3}>
                <Priedas image={Suris} name="Mozzarella sūris" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Kumpis} name="Kumpis" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Saliamis} name="Saliamis" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Sonine} name="Šoninė" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Farsas} name="Malta mėsa" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Jautiena} name="Jautienos kumpeliai" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Vistiena} name="Vištiena" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Krevetes} name="Krevetės" size={this.state.checkedSize} kaina30={1.25} kaina40={1.90} kaina50={2.50} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Svogunai} name="Mėlynieji svogūnai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Paprika} name="Paprika" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Agurkeliai} name="Agurkėliai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={DzPomidorai} name="Saulėje džiovinti pomidorai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={VPomidorai} name="Vyšniniai pomidorai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Jelapenai} name="Jelapenai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Svoguneliai} name="Marinuoti svogūnėliai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Baklazanai} name="Kepti baklažanai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Porai} name="Porai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Brokoliai} name="Brokoliai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Pievagrybiai} name="Pievagrybiai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Alyvuoges} name="Alyvuogės" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Ananasai} name="Ananasai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Rukola} name="Rukola" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Spinatai} name="Špinatai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={Bazilikas} name="Bazilikai" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
                <Priedas image={RSuris} name="Rūkytas sūris" size={this.state.checkedSize} kaina30={0.65} kaina40={0.95} kaina50={1.25} buttonClick={this.addPrice}></Priedas>
              </Carousel>
            </div>
            <div className="PadazaiBox">
              <span className="Padazai">Padažas</span>
              <table className="Souce1">
                <tbody>
                  <tr>
                    <td ><input id="souce1" type="radio" checked={this.state.souce === 'Česnakinis'} onChange={(e) => this.onSouceChanged('Česnakinis')} className="Souce1" /><label htmlFor="souce1">Česnakinis</label></td>
                    <td><input id="souce2" type="radio" checked={this.state.souce === 'Pikantiškas'} onChange={(e) => this.onSouceChanged('Pikantiškas')} className="Souce1" /><label htmlFor="souce2">Pikantiškas</label></td>
                  </tr>
                </tbody>
              </table>
              <table className="Souce2">
                <tbody>
                  <tr>
                    <td><input id="souce3" type="radio" checked={this.state.souce === 'Aštrus'} onChange={(e) => this.onSouceChanged('Aštrus')} className="Souce2" /><label htmlFor="souce3">Aštrus</label></td>
                    <td><input id="souce4" type="radio" checked={this.state.souce === 'BBQ'} onChange={(e) => this.onSouceChanged('BBQ')} className="Souce2" /><label htmlFor="souce4">BBQ</label></td>
                    <td><input id="souce5" type="radio" checked={this.state.souce === 'Kečupas'} onChange={(e) => this.onSouceChanged('Kečupas')} className="Souce2" /><label htmlFor="souce5">Kečupas</label></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id, price, priedai, atmesti, padazas, dydis, title) => { dispatch(addToCart(id, price, priedai, atmesti, padazas, dydis, title)) }
  }
}

export default connect(null, mapDispatchToProps)(PicaPopup)