import Product from './Product';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PicaSu from '../img/PicaSu.png';
import Kumpiu from '../img/Kumpiu.png';
import Vistiena from '../img/Vistiena.png';
import Sonine from '../img/Sonine.png';
import Mesa from '../img/Mesa.png';
import Krevetes from '../img/Krevetemis.png';
import Pica from '../img/Pica.png';
import Vegetariska from '../img/Vegetariska.png';
import Salami from '../img/Saliamiu.png';
import Jautiena from '../img/Jautiena.png';
import nemokamai from '../img/3picaNemokamai.png';

class Picos extends Component {

  render() {
    let itemListHam = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Ham": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListChicken = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Chicken": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListBacon = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Bacon": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListMeat = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Meat": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListShrimps = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Shrimps": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListVegan = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Vegan": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListSalami = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Salami": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    let itemListBeef = this.props.items.map(item => {
      return (
        (() => {
          switch (item.type2) {
            case "Beef": return <Product tipas={item.type} key={item.id} id={item.id} klase="ProduktasPicos" imgklase="PicosImg" image={item.img} number={item.number} tekstas={item.title} kaina={item.price} desc={item.desc} title={item.title} />;
          }
        })()
      )
    })
    return (
      <div className="Produktai">
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Kumpiu}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListHam}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Vistiena}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListChicken}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Sonine}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListBacon}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Mesa}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListMeat}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Krevetes}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListShrimps}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Vegetariska}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListVegan}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Salami}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListSalami}
        </div>
        <div className="PicaTitle">
          <img className="PicaSu" src={PicaSu} alt="" />
          <img className="PicaTitleImg" src={Jautiena}  alt="" />
        </div>
        <div className="ProduktaiPicos">
          {itemListBeef}
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.items
  }
}

export default connect(mapStateToProps)(Picos)
