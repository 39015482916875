import React, { Component } from 'react';
import fasadas from '../img/pristatymas.png';

class Contacts extends Component {

    render() {
        return (<div className="Produktai">
            <img className="ContactsImg" src={fasadas} />
            {/* <div className="ContactsAdr">
                <span>Durpių g. 40, Pagiriai, Vilniaus raj.</span>
                <span>Tel. 8 5 260 31 00</span>
                <span>El. paštas info@profpizza.lt</span>
                <span>Darbo laikas 8-22 val.</span>
                <span>Užsakymą privežame iki adrese nurodyto namo</span>
            </div>
            
            
            <div className="ContactsVez">Vežame į šias vietoves</div>
            <div className="ContactsVietoves">
                <div className="ContactsRajonas">
                    <h4>Vilniuje</h4>
                    <ul>
                        <li>Aukštieji Paneriai</li>
                        <li>Daniliškės</li>
                        <li>Dilgynė</li>
                        <li>Gariūnai</li>
                        <li>Jočionys</li>
                        <li>Kazbėjai</li>
                        <li>Kirtimai</li>
                        <li>Liudvinavas</li>
                        <li>Mačiuliškės</li>
                        <li>Metropolis</li>
                        <li>Mickūnai</li>
                        <li>Pakupečiai</li>
                        <li>Raisteliai</li>
                        <li>Salininkai</li>
                        <li>Trakų Vokė</li>
                        <li>Užusieniai</li>
                    </ul>
                </div>
                <div className="ContactsRajonas">
                    <h4>Vilniaus rajone</h4>
                    <ul>
                        <li>Didieji Lygainiai</li>
                        <li>Didžialaukis</li>
                        <li>Dusinėnai</li>
                        <li>Juodbaliai</li>
                        <li>Juodšiliai</li>
                        <li>Kalveliai</li>
                        <li>Kalviškės</li>
                        <li>Keturiasdešimt totorių</li>
                        <li>Kryžiškės</li>
                        <li>Marijampolis</li>
                        <li>Mažieji Lygainiai</li>
                        <li>Melekonys</li>
                        <li>Merešlėnai</li>
                        <li>Mikašiūnai</li>
                        <li>Miškiniai</li>
                        <li>Pagiriai</li>
                        <li>Papiškės</li>
                        <li>Parudaminys</li>
                        <li>Prūdiškės</li>
                        <li>Rudamina</li>
                        <li>Skurubutėnai</li>
                        <li>Tarnėnai</li>
                        <li>Totorinė</li>
                        <li>Vaidotai</li>
                        <li>Vaira</li>
                        <li>Valčiūnai</li>
                    </ul>
                </div>
                <div className="ContactsRajonas">
                    <h4>Šalčininkų rajone</h4>
                    <ul>
                        <li>Baltoji Vokė</li>
                        <li>Rūdninkai</li>
                        <li>Vakariškės</li>
                        <li>Žagarinė</li>
                    </ul>
                </div>
            </div> */}

        </div>)

    }
}
export default Contacts;