import sevenUp from '../../img/Drinks/7up.png';
import mirinda from '../../img/Drinks/mirinda.png';
import pepsi from '../../img/Drinks/pepsi.png';
import kava from '../../img/Drinks/Kava.png';
import nuggets from '../../img/Menu/Nuggets.png';
import snack from '../../img/Menu/Snack.png';
import fri from '../../img/Menu/Fri.png';
import pica1 from '../../img/Pizza/Pizza-1.png';
import pica2 from '../../img/Pizza/Pizza-2.png';
import pica3 from '../../img/Pizza/Pizza-3.png';
import pica4 from '../../img/Pizza/Pizza-4.png';
import pica5 from '../../img/Pizza/Pizza-5.png';
import pica6 from '../../img/Pizza/Pizza-6.png';
import pica7 from '../../img/Pizza/Pizza-7.png';
import pica8 from '../../img/Pizza/Pizza-8.png';
import pica9 from '../../img/Pizza/Pizza-9.png';
import pica10 from '../../img/Pizza/Pizza-10.png';
import pica11 from '../../img/Pizza/Pizza-11.png';
import pica12 from '../../img/Pizza/Pizza-12.png';
import pica13 from '../../img/Pizza/Pizza-13.png';
import pica14 from '../../img/Pizza/Pizza-14.png';
import pica15 from '../../img/Pizza/Pizza-15.png';
import pica16 from '../../img/Pizza/Pizza-16.png';
import pica17 from '../../img/Pizza/Pizza-17.png';
import pica18 from '../../img/Pizza/Pizza-18.png';
import pica19 from '../../img/Pizza/Pizza-19.png';
import pica20 from '../../img/Pizza/Pizza-20.png';
import pica21 from '../../img/Pizza/Pizza-21.png';
import pica22 from '../../img/Pizza/Pizza-22.png';
import pica23 from '../../img/Pizza/Pizza-23.png';
import pica24 from '../../img/Pizza/Pizza-24.png';
import picaNr1 from '../../img/01.png';
import picaNr2 from '../../img/02.png';
import picaNr3 from '../../img/03.png';
import picaNr4 from '../../img/04.png';
import picaNr5 from '../../img/05.png';
import picaNr6 from '../../img/06.png';
import picaNr7 from '../../img/07.png';
import picaNr8 from '../../img/08.png';
import picaNr9 from '../../img/09.png';
import picaNr10 from '../../img/10.png';
import picaNr11 from '../../img/11.png';
import picaNr12 from '../../img/12.png';
import picaNr13 from '../../img/13.png';
import picaNr14 from '../../img/14.png';
import picaNr15 from '../../img/15.png';
import picaNr16 from '../../img/16.png';
import picaNr17 from '../../img/17.png';
import picaNr18 from '../../img/18.png';
import picaNr19 from '../../img/19.png';
import picaNr20 from '../../img/20.png';
import picaNr21 from '../../img/21.png';
import picaNr22 from '../../img/22.png';
import picaNr23 from '../../img/23.png';
import picaNr24 from '../../img/24.png';
import pita from '../../img/Menu/Pita.png';
import pitaG from '../../img/Menu/PitaGyros.png';
import pitaCheese from '../../img/Menu/PitaCheese.png';
import pitaFri from '../../img/Menu/PitaFri.png';
import kebabLavas from '../../img/Menu/KebabLavas.png';
import kebabTortilia from '../../img/Menu/KebabTortilia.png';
import mesa from '../../img/Menu/Mesa.png';
import ledai1 from '../../img/Menu/Ledai1.png';
import ledai2 from '../../img/Menu/Ledai2.png';
import ledai3 from '../../img/Menu/Ledai3.png';
import souce from '../../img/Menu/Souce.png';
import souceHot from '../../img/Menu/SouceHot.png';
import soucePiq from '../../img/Menu/SoucePiq.png';
import souceBBQ from '../../img/Menu/SouceBBQ.png';
import souceGarlic from '../../img/Menu/SouceGarlic.png';

import { ADD_TO_CART, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY } from '../actions/action-types/cart-actions'

const initState = {
    items: [
        { id: 1, type: 'Drinks', title: '7Up 0.5l', price: 1.19, tara: 0.10, img: sevenUp },
        { id: 2, type: 'Drinks', title: 'Mirinda 0.5l', price: 1.19, tara: 0.10, img: mirinda },
        { id: 3, type: 'Drinks', title: 'Pepsi 0.5l', price: 1.19, tara: 0.10, img: pepsi },
        // { id: 4, type: 'Drinks', title: 'Kava', price: 0, img: kava },
        { id: 11, type: 'Pizza', type2: 'Ham', description: "Pica su kumpiu [1]", title: 'Virtas kumpis', desc: ['paprika', 'alyvuogės', 'rūkytas sūris', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica1, number: picaNr1 },
        { id: 12, type: 'Pizza', type2: 'Ham', description: "Pica su kumpiu [2]", title: 'Virtas kumpis', desc: ['mėlynieji svogūnai', 'vyšniniai pomidorai', 'šviežias bazilikas', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica2, number: picaNr2 },
        { id: 13, type: 'Pizza', type2: 'Ham', description: "Pica su kumpiu [3]", title: 'Virtas kumpis', desc: ['pievagrybiai', 'mėlynieji svogūnai', 'brokoliai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica3, number: picaNr3 },
        { id: 14, type: 'Pizza', type2: 'Chicken', description: "Pica su vištiena [4]", title: 'Vištienos filė', desc: ['paprika', 'ananasai', 'šviežias bazilikas', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica4, number: picaNr4 },
        { id: 15, type: 'Pizza', type2: 'Chicken', description: "Pica su vištiena [5]", title: 'Vištienos filė', desc: ['pievagrybiai', 'porai', 'brokoliai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica5, number: picaNr5 },
        { id: 16, type: 'Pizza', type2: 'Chicken', description: "Pica su vištiena [6]", title: 'Vištienos filė', desc: ['alyvuogės', 'saulėje džiovinti pomidorai', 'špinatai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica6, number: picaNr6 },
        { id: 17, type: 'Pizza', type2: 'Bacon', description: "Pica su šonine [7]", title: 'Rūkyta šoninė', desc: ['pievagrybiai', 'kepti baklažanai', 'paprika', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica7, number: picaNr7 },
        { id: 18, type: 'Pizza', type2: 'Bacon', description: "Pica su šonine [8]", title: 'Rūkyta šoninė', desc: ['vyšniniai pomidorai', 'mėlynieji svogūnai', 'rukola', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica8, number: picaNr8 },
        { id: 19, type: 'Pizza', type2: 'Bacon', description: "Pica su šonine [9]", title: 'Rūkyta šoninė', desc: ['špinatai', 'rūkytas sūris', 'plaktas kiaušinis', 'porai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica9, number: picaNr9 },
        { id: 20, type: 'Pizza', type2: 'Meat', description: "Pica su malta mėsa [10]", title: 'Malta kiauliena', desc: ['paprika', 'porai', 'kepti baklažanai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica10, number: picaNr10 },
        { id: 21, type: 'Pizza', type2: 'Meat', description: "Pica su malta mėsa [11]", title: 'Malta kiauliena', desc: ['alyvuogės', 'vyšniniai pomidorai', 'rukola', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica11, number: picaNr11 },
        { id: 22, type: 'Pizza', type2: 'Meat', description: "Pica su malta mėsa [12]", title: 'Malta kiauliena', desc: ['alyvuogės', 'paprika', 'mėlynieji svogūnai', 'pievagrybiai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica12, number: picaNr12 },
        { id: 23, type: 'Pizza', type2: 'Shrimps', description: "Pica su krevetėmis [13]", title: 'Krevetės', desc: ['mėlynieji svogūnai', 'alyvuogės', 'špinatai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica13, number: picaNr13 },
        { id: 24, type: 'Pizza', type2: 'Shrimps', description: "Pica su krevetėmis [14]", title: 'Krevetės', desc: ['saulėje džiovinti pomidorai', 'rukola', 'mėlynieji svogūnai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica14, number: picaNr14 },
        { id: 25, type: 'Pizza', type2: 'Shrimps', description: "Pica su krevetėmis [15]", title: 'Krevetės', desc: ['vyšniniai pomidorai', 'rūkytas sūris', 'paprika', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica15, number: picaNr15 },
        { id: 26, type: 'Pizza', type2: 'Vegan', description: "Pica vegetariška [16]", title: 'Pievagrybiai', desc: ['mėlynieji svogūnai', 'špinatai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica16, number: picaNr16 },
        { id: 27, type: 'Pizza', type2: 'Vegan', description: "Pica vegetariška [17]", title: 'Vyšniniai pomidorai', desc: ['šviežias bazilikas', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica17, number: picaNr17 },
        { id: 28, type: 'Pizza', type2: 'Vegan', description: "Pica vegetariška [18]", title: 'Kepti baklažanai', desc: ['saulėje džiovinti pomidorai', 'mėlynieji svogūnai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica18, number: picaNr18 },
        { id: 29, type: 'Pizza', type2: 'Salami', description: "Pica su saliamiu [19]", title: 'Rūkytas saliamis', desc: ['pievagrybiai', 'agurkėliai', 'rūkytas sūris', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica19, number: picaNr19 },
        { id: 30, type: 'Pizza', type2: 'Salami', description: "Pica su saliamiu [20]", title: 'Rūkytas saliamis', desc: ['vyšniniai pomidorai', 'svogūnėliai', 'agurkėliai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica20, number: picaNr20 },
        { id: 31, type: 'Pizza', type2: 'Salami', description: "Pica su saliamiu [21]", title: 'Rūkytas saliamis', desc: ['alyvuogės', 'porai', 'svogūnėliai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica21, number: picaNr21 },
        { id: 32, type: 'Pizza', type2: 'Beef', description: "Pica su jautiena [22]", title: 'Rūkytas jautienos kumpelis', desc: ['pievagrybiai', 'agurkėliai', 'mėlynieji svogūnai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica22, number: picaNr22 },
        { id: 33, type: 'Pizza', type2: 'Beef', description: "Pica su jautiena [23]", title: 'Rūkytas jautienos kumpelis', desc: ['paprika', 'porai', 'svogūnėliai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica23, number: picaNr23 },
        { id: 34, type: 'Pizza', type2: 'Beef', description: "Pica su jautiena [24]", title: 'Rūkytas jautienos kumpelis', desc: ['mėlynieji svogūnai', 'alyvuogės', 'vyšniniai pomidorai', 'Mozzarella sūris', 'pomidorų padažas'], price: 11.49, img: pica24, number: picaNr24 },
        { id: 40, id2: 400, type: 'PitaG', title: 'Pita-Gyros su vištiena', size1: 'Maža', size2: 'Didelė', price: 6.99, price2: 9.99, img: pitaG },
        { id: 41, id2: 410, type: 'PitaG', title: 'Pita-Gyros su vištiena ir sūriu', size1: 'Maža', size2: 'Didelė', price: 7.29, price2: 10.39, img: pitaG },
        { id: 42, id2: 420, type: 'PitaG', title: 'Pita-Gyros su kiauliena', size1: 'Maža', size2: 'Didelė', price: 6.99, price2: 9.99, img: pitaG },
        { id: 43, id2: 430, type: 'PitaG', title: 'Pita-Gyros su kiauliena ir sūriu', size1: 'Maža', size2: 'Didelė', price: 7.29, price2: 10.39, img: pitaG },
        { id: 44, id2: 440, id3: 441, type: 'KebabTripple', title: 'Kebabas lavaše su vištiena', size1: 'Mažas', size2: 'Didelis', size3: 'XL', price: 4.29, price2: 5.19, price3: 6.29, img: kebabLavas },
        { id: 45, id2: 450, id3: 451, type: 'KebabTripple', title: 'Kebabas lavaše su kiauliena', size1: 'Mažas', size2: 'Didelis', size3: 'XL', price: 4.29, price2: 5.19, price3: 6.29, img: kebabLavas },
        { id: 46, id2: 460, id3: 461, type: 'Kebab', title: 'Kebabas tortilijoje su vištiena', size1: 'Mažas', size2: 'Vidutinis', price: 4.29, price2: 5.19, img: kebabTortilia },
        { id: 47, id2: 470, id3: 471, type: 'Kebab', title: 'Kebabas tortilijoje su kiauliena', size1: 'Mažas', size2: 'Vidutinis', price: 4.29, price2: 5.19, img: kebabTortilia },
        { id: 48, type: 'Snacks', title: 'Kebabo mėsa su fri, daržovėmis ir padažu', price: 6.99, img: mesa },
        { id: 49, id2: 490, id3: 491, type: 'SnacksTripple', title: 'Nuggets', size1: '4 vnt', size2: '6 vnt', size3: '12 vnt su padažu', price: 2.29, price2: 2.99, price3: 5.99, img: nuggets },
        {id: 50, id2: 500, id3: 501, type: 'SnacksTripple', title: 'Fri bulvytės', size1: 'Maža', size2: 'Vidutinė', size3: 'Didelė', price: 1.29, price2: 2.59, price3: 3.89, img: fri },
        { id: 51, type: 'Snacks', title: 'Snack', price: 3.29, img: snack },
        { id: 52, type: 'IceCream', title: 'Ledai ragelyje', price: 1.59, img: ledai1 },
       // { id: 53, type: 'IceCream', title: 'Ledai indelyje', price: 1.89, img: ledai2 },
        { id: 54, type: 'IceCream', title: 'Ledai indelyje su uogiene', price: 2.89, img: ledai3 },
        { id: 55, id2: 550, type: 'Pita', title: 'Pita', size1: 'Maža', size2: 'Didelė', price: 1.19, price2: 1.79, img: pita },
        { id: 56, id2: 560, type: 'Pita', title: 'Pita su sūriu', size1: 'Maža', size2: 'Didelė', price: 1.99, price2: 2.79, img: pitaCheese },
        { id: 57, id2: 570, type: 'Pita', title: 'Pita su sūriu ir Fri', size1: 'Maža', size2: 'Didelė', price: 3.19, price2: 4.29, img: pitaFri },
        { id: 58, type: 'Souce', title: 'Česnakinis', price: 0.70, img: souceGarlic },
        { id: 59, type: 'Souce', title: 'Pikantiškas', price: 0.70, img: soucePiq },
        { id: 60, type: 'Souce', title: 'Aštrus', price: 0.70, img: souceHot },
        { id: 61, type: 'Souce', title: 'BBQ', price: 0.70, img: souceBBQ },
        { id: 62, type: 'Souce', title: 'Kečupas', price: 0.40, img: souce }
    ],
    addedItems: [],
    shipping: 3.40,
    total: 0,
    countItems: 0

}
const cartReducer = (state = initState, action) => {

    //INSIDE HOME COMPONENT
    if (action.type === ADD_TO_CART) {
        let allItems =JSON.parse(JSON.stringify(state.items));
        let addedItem = allItems.find(item => item.id === action.id);
        if (addedItem == null) {
            addedItem = allItems.find(item => item.id2 === action.id)
        }
        if (addedItem == null) {
            addedItem = allItems.find(item => item.id3 === action.id)
        }

        if (addedItem.type === 'Pizza') {
            addedItem.id = Math.random();
        } else {
            addedItem.id = action.id;
        }
        addedItem.price = action.price;
        if (addedItem.tara) {
            addedItem.price = addedItem.price + addedItem.tara;
        }
        addedItem.priedai = action.priedai;
        addedItem.atmesti = action.atmesti;
        addedItem.padazas = action.padazas;
        addedItem.dydis = action.dydis;
        if (action.title) {
            addedItem.title = action.title;
        }
        let addedPrice = addedItem.price;
        //Trecias kebabas/pita nemokamai
        if (addedItem.type === 'Kebab') {
            let kebabs = state.addedItems.filter(item => 'Kebab' === item.type)
            let kebabCount = 0;
            if (kebabs && kebabs.length > 0) {
                for (let kebab of kebabs) {
                    kebabCount += kebab.quantity;
                }
                if ((kebabCount +1) % 3 === 0) {
                    addedPrice = 0;
                }
            }
        }
        if (addedItem.type === 'PitaG') {
            let pitas = state.addedItems.filter(item => 'PitaG' === item.type)
            let pitasCount = 0;
            if (pitas && pitas.length > 0) {
                for (let pita of pitas) {
                    pitasCount += pita.quantity;
                }
                if ((pitasCount +1) % 3 === 0) {
                    addedPrice = 0;
                }
            }
        }
        let new_total = Math.round((state.total + addedPrice) * 100) / 100;
        if (new_total > 24.99) {
            state.shipping = 0;
        }
        // if (addedItem.type === 'Pizza') {
        //     let pizza_item = state.addedItems.find(item => 'Pizza' === item.type)
        //     if (pizza_item) {
        //         state.shipping = 0;
        //     }
        // }
        //check if the action id exists in the addedItems
        let existed_item = state.addedItems.find(item => addedItem.id === item.id)
        if (existed_item) {
            let new_items = state.addedItems.filter(item => item.id !== addedItem.id)
            addedItem.quantity = existed_item.quantity + 1;
            
            return {
                ...state,
                addedItems: [...new_items, addedItem],
                total: new_total,
                countItems: state.countItems += 1
            }
        }
        else {
            addedItem.quantity = 1;
            return {
                ...state,
                addedItems: [...state.addedItems, addedItem],
                total: new_total,
                countItems: state.countItems += 1
            }
        }
    }
    if (action.type === REMOVE_ITEM) {
        let itemToRemove = state.addedItems.find(item => action.id === item.id)
        let new_items = state.addedItems.filter(item => action.id !== item.id)

        //calculating the total
        let newTotal = Math.round((state.total - (itemToRemove.price * itemToRemove.quantity)) * 100) / 100
        console.log(itemToRemove)
        return {
            ...state,
            addedItems: new_items,
            total: newTotal,
            countItems: state.countItems - itemToRemove.quantity
        }
    }
    //INSIDE CART COMPONENT
    if (action.type === ADD_QUANTITY) {
        let addedItem = state.items.find(item => item.id === action.id)
        addedItem.quantity += 1
        let newTotal =  Math.round((state.total + addedItem.price) * 100) / 100
        return {
            ...state,
            total: newTotal
        }
    }
    if (action.type === SUB_QUANTITY) {
        let addedItem = state.items.find(item => item.id === action.id)
        //if the qt == 0 then it should be removed
        if (addedItem.quantity === 1) {
            let new_items = state.addedItems.filter(item => item.id !== action.id)
            let newTotal =  Math.round((state.total + addedItem.price) * 100) / 100
            return {
                ...state,
                addedItems: new_items,
                total: newTotal
            }
        }
        else {
            addedItem.quantity -= 1
            let newTotal = Math.round((state.total + addedItem.price) * 100) / 100
            return {
                ...state,
                total: newTotal
            }
        }

    }
    return state
}
export default cartReducer;